export default function FooterInfoText() {
  const css = { maxWidth: '105%' }
  return (
    <div className="grid grid-cols-3 gap-4 py-6 mx-0 border-b-2 border-gray-300 sm:grid-cols-3 lg:mx-4 f-info-img-text">
      <div className="relative flex flex-col items-center px-2 py-5 space-x-3 sm:px-6 sm:flex-row f-info-div">
        <div className="flex-shrink-0 mb-2 sm:mb-0 info-img">
          <img
            width={97}
            height={64}
            style={css}
            fetchPriority="high"
            className="w-auto max-w-full"
            src="https://dacdn2.damensch.com/damensch/icons/warrenty-image.svg"
            alt="Replacement Warranty"
          />
        </div>
        <div className="flex min-w-0 sm:flex-1 mob-ml-0">
          <p className="text-sm font-medium text-center text-gray-900 sm:text-left f-info-p">
            Replacement Warranty
          </p>
        </div>
      </div>
      <div className="relative flex flex-col items-center px-2 py-5 space-x-3 sm:px-6 sm:flex-row f-info-div">
        <div className="flex-shrink-0 mb-2 sm:mb-0 info-img">
          <img
            width={97}
            height={64}
            style={css}
            fetchPriority="high"
            className="w-auto max-w-full"
            src="https://dacdn2.damensch.com/damensch/icons/premium-image.svg"
            alt="The Finest Materials"
          />
        </div>
        <div className="flex min-w-0 sm:flex-1 mob-ml-0">
          <p className="text-sm font-medium text-center text-gray-900 sm:text-left f-info-p">
            The Finest Materials
          </p>
        </div>
      </div>
      <div className="relative flex flex-col items-center px-2 py-5 space-x-3 sm:px-6 sm:flex-row f-info-div">
        <div className="flex-shrink-0 mb-2 sm:mb-0 info-img">
          <img
            width={97}
            height={64}
            style={css}
            fetchPriority="high"
            className="w-auto max-w-full"
            src="https://dacdn2.damensch.com/damensch/icons/15days.svg"
            alt="Easy Returns & Exchange"
          />
        </div>
        <div className="flex min-w-0 sm:flex-1 mob-ml-0">
          <p className="text-sm font-medium text-center text-gray-900 sm:text-left f-info-p">
            Easy Returns & Exchange
          </p>
        </div>
      </div>
    </div>
  )
}
